import * as Yup from 'yup';
import { useState, useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { signIn } from '../../../domain/hooks';
import AppContext from '../../../context/AppContext';

// ----------------------------------------------------------------------

function LoginForm() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [showPassword, setShowPassword] = useState(false);
  const [passError, setPassError] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('El correo debe ser válido').required('El correo es necesario'),
    password: Yup.string().required('La contraseña es obligatoria')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        const user = await signIn({ username: values.email, password: values.password });
        setPassError(false);
        appContext.setJWT(user.signInUserSession.idToken.jwtToken);
        appContext.setExpiration(user.signInUserSession.idToken.payload.exp * 1000);
        appContext.setEmail(user.attributes.email);
        appContext.setGroup(user.signInUserSession.idToken.payload['custom:roles']);
        // console.log('ROLE', user.signInUserSession.idToken.payload['custom:roles']);
        navigate('/dashboard/profile', { replace: true });
      } catch {
        setPassError(true);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Correo electrónico"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Contraseña"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && (errors.password || passError))}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Recuérdame"
          />

          <Link component={RouterLink} variant="subtitle2" to="/recovery">
            Recuperar contraseña
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Acceder
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

export default LoginForm;
