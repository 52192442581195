import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Snackbar, Alert } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { LoadingButton } from '@mui/lab';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { signUp } from '../../../domain/hooks';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [snackbar, setSnackbar] = useState(false);

  const roles = ['Doctor', 'Administrador', 'Investigador'];

  const RegisterSchema = Yup.object().shape({
    role: Yup.string().required('Debe seleccionar un tipo.').oneOf(roles),
    firstName: Yup.string()
      .min(2, 'Demasiado corto.')
      .max(50, 'Demasiado largo.')
      .required('El nombre es necesario.'),
    lastName: Yup.string()
      .min(2, 'Demasiado corto')
      .max(50, 'Demasiado largo.')
      .required('El apellido es obligatorio'),
    email: Yup.string().email('El correo debe ser válido.').required('El correo es necesario.'),
    password: Yup.string()
      .min(8, 'La contraseña debe tener 8 caracteres o más.')
      .required('La contraseña es obligatoria.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        'Debe contener más de 8 caracteres, una mayúscula, una minúscula y un número.'
      )
  });

  const formik = useFormik({
    initialValues: {
      role: 'Doctor',
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { resetForm }) => {
      await signUp({
        username: values.email,
        password: values.password,
        fullname: `${values.firstName} ${values.lastName}`,
        group: values.role
      });
      setSnackbar(true);
      resetForm();
      // Do not send anywhere, but say user created
      // navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <FormControl fullWidth>
            <InputLabel id="tipo-usuario">Tipo de usuario</InputLabel>
            <Select
              fullWidth
              name="Role"
              label="Tipo de usuario"
              {...getFieldProps('role')}
              error={Boolean(touched.role && errors.role)}
              helperText={touched.role && errors.role}
            >
              <MenuItem value="Administrador">Administrador</MenuItem>
              <MenuItem value="Doctor">Doctor</MenuItem>
              <MenuItem value="Investigador">Investigador</MenuItem>
            </Select>
          </FormControl>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Nombre"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Apellido"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Correo electrónico"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Contraseña"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Registrar
          </LoadingButton>
        </Stack>
      </Form>
      <Snackbar
        sx={{ zIndex: '1' }}
        open={snackbar}
        autoHideDuration={4000}
        onClose={() => setSnackbar(false)}
      >
        <Alert
          onClose={() => setSnackbar(false)}
          severity="success"
          sx={{ zIndex: '1', width: '100%' }}
        >
          Usuario creado correctamente
        </Alert>
      </Snackbar>
    </FormikProvider>
  );
}
