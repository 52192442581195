// material
import { Box, Card, Container, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { RegisterDoctorForm } from '../components/authentication/register';
// components
import Page from '../components/Page';
import { SatisfactionForm } from '../components/_dashboard/doctors';
import AppContext from '../context/AppContext';
import { API_URL } from '../properties';

// ----------------------------------------------------------------------

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: theme.spacing(0),
  display: 'flex',
  minHeight: '50vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1, 0)
}));

// ----------------------------------------------------------------------

export default function RegisterDoctor() {
  const appContext = useContext(AppContext);
  const [doctorData, setDoctorData] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const isFormNecessary = async () => {
      try {
        const resForm = await fetch(`${API_URL}/v2/doctor/quest?doctor-id=${appContext.email}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: appContext.jwt
          }
        });
        if (resForm.status === 200) {
          const num = await resForm.json();
          setOpen(num >= 30);
        }
        // setOpen(true); // Testing
      } catch (e) {
        // console.log(e);
      }
    };
    isFormNecessary();
  }, [appContext.email]);

  useEffect(() => {
    const getDoctorData = async () => {
      try {
        const resDoctor = await fetch(`${API_URL}/v2/doctor?doctor-id=${appContext.email}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: appContext.jwt
          }
        });
        if (resDoctor.status === 200) {
          const doctorData = await resDoctor.json();
          setDoctorData(doctorData);
          if (doctorData === null) {
            appContext.setForceRegister(true);
          } else {
            appContext.setForceRegister(false);
          }
          console.log('Doctor data', doctorData);
        }
      } catch (e) {
        appContext.setForceRegister(true);
        // console.log(e);
      }
    };
    getDoctorData();
  }, [appContext.email]);

  const onSubmit = async (data) => {
    // console.log(JSON.stringify(data));
    try {
      const resDoctor = await fetch(`${API_URL}/v2/doctor?doctor-id=${appContext.email}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: appContext.jwt
        },
        body: JSON.stringify(data)
      });
      // console.log(doctorData);
      if (resDoctor.status === 201) {
        // console.log(`201: ${resDoctor.status}`);
        // const doctorData = await resDoctor.json();
        setDoctorData(data);
        appContext.setForceRegister(false);
      } else {
        // Something should be done if it fails, but it is not specified
        // console.log(`Not 201: ${resDoctor.status}`);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const onSubmitSatisfaction = async (data) => {
    try {
      const resSatisfaction = await fetch(
        `${API_URL}/v2/doctor/quest?doctor-id=${appContext.email}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appContext.jwt
          },
          body: JSON.stringify(data)
        }
      );
      // console.log(doctorData);
      if (resSatisfaction.status === 201) {
        setOpen(false);
        setOpenSnackbar(true);
        appContext.setForceRegister(false);
      } else {
        // Something should be done if it fails, but it is not specified
      }
    } catch (e) {
      // console.log(e);
    }
  };

  return (
    <RootStyle title="Perfil | ADAF">
      <Container maxWidth="xl">
        <ContentStyle fullWidth>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h4" gutterBottom>
              Registrar su perfil de usuario
            </Typography>
          </Box>
          <Card sx={{ mt: 2, p: 5, pt: 7, pb: 7 }} fullWidth>
            <Dialog open={open} onClose={handleClose} maxWidth="xl">
              <DialogTitle>Encuesta de satisfacción</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Por favor rellene la encuesta para saber la utilidad del servicio para:
                </DialogContentText>
              </DialogContent>
              <SatisfactionForm onSubmit={(data) => onSubmitSatisfaction(data)} />
            </Dialog>

            <RegisterDoctorForm doctorData={doctorData} onSubmit={(data) => onSubmit(data)} />

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert severity="success" sx={{ width: '100%' }}>
                Formulario enviado!
              </Alert>
            </Snackbar>
          </Card>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
