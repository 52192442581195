import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
  aws_cognito_region: process.env.REACT_APP_COGNITO_USER_POOL_REGION,
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_USER_POOL_APP_CLIENT
});

async function getCurrentUser() {
  try {
    await Auth.currentSession();
    const user = await Auth.currentAuthenticatedUser();
    // console.log('USER', user);
    return user;
  } catch {
    // console.log('No user authenticated');
    return null;
  }
}

async function getCurrentUserInfo() {
  try {
    const userInfo = await Auth.currentUserInfo();
    // console.log('USER INFO', userInfo);
    return userInfo;
  } catch {
    // console.log('No user authenticated');
    return null;
  }
}

async function signIn({ username, password }) {
  try {
    const user = await Auth.signIn(username, password);
    // console.log('USER', user);
    return user;
  } catch (error) {
    // console.error('Error on sign in', error);
    return null;
  }
}

async function signUp({ username, password, fullname, group }) {
  try {
    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        email: username, // optional
        'custom:fullname': fullname,
        'custom:roles': group // optional - E.164 number convention
        // TODO: Insert custom attributes
      },
      clientMetadata: {
        group
      }
    });
    // console.log('USER', user);
    return user;
  } catch (error) {
    // console.error('Error on sign up', error);
    return null;
  }
}

async function forgotPasswordRequestCode({ email }) {
  try {
    await Auth.forgotPassword(email);
  } catch (error) {
    // console.log(error);
  }
}

async function changePassword({ email, code, newPassword }) {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  } catch (error) {
    // console.log(error);
  }
}

async function resendConfirmationCode({ username }) {
  try {
    await Auth.resendSignUp(username);
    // console.log('Confirmation code resent successfully');
  } catch (err) {
    // console.log('Error resending confirmation code: ', err);
  }
}

async function signOut() {
  try {
    await Auth.signOut();
    // console.log('Signed out');
  } catch (error) {
    // console.log('Error signing out: ', error);
  }
}

export {
  getCurrentUser,
  getCurrentUserInfo,
  signIn,
  signUp,
  signOut,
  resendConfirmationCode,
  forgotPasswordRequestCode,
  changePassword
};
