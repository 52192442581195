// material
import { Box, Card, CardHeader, Typography, Button, Collapse } from '@mui/material';
import { merge } from 'lodash';
// React
import { useEffect, useState } from 'react';
// Apexcharts
import ReactApexChart from 'react-apexcharts';
import {
  COLOR_LAST_CHECK,
  COLOR_REASON,
  COLOR_STAGE,
  COLOR_TIME_DIAGNOSIS
} from '../../../utils/constants';
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

export default function GraphEvolution(props) {
  const { patientData, patientEvolution } = props;

  const [moreInfo, setMoreInfo] = useState(false);
  const [lastCheck, setLastCheck] = useState([]);
  const [stage, setStage] = useState([]);
  const [timeDiagnosis, setTimeDiagnosis] = useState([]);
  const [reasonCheck, setReasonCheck] = useState([]);
  const [datesStr, setDatesStr] = useState([]);
  const [dates, setDates] = useState([]);

  const getClosestStage = (patientData, date) => {
    let distance = Infinity;
    let stage = 0;
    const stagesDates = patientData.stage.map((el) => new Date(Object.keys(el)[0]));
    stagesDates.forEach((stageDate) => {
      const newDistance = Math.abs(stageDate - date);
      if (newDistance < distance) {
        distance = newDistance;
        // console.log(
        //   patientData.stage.filter((el) => Object.keys(el)[0] === stageDate.toISOString())
        // );
        [stage] = Object.values(
          patientData.stage.filter((el) => Object.keys(el)[0] === stageDate.toISOString())[0]
        );
      }
    });
    console.assert(stage > 0);
    return stage;
  };

  useEffect(() => {
    // Get dates
    const newDates = Object.keys(patientEvolution).map((key) => new Date(Number(key)));
    setDates(newDates);
    const newDatesStr = Object.keys(patientEvolution)
      .map((key) => new Date(Number(key)))
      .map((date) => `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
    setDatesStr(newDatesStr);
    // console.log(datesStr);

    // Get questions from response
    const newLastCheck = Object.keys(patientEvolution)
      .map((key) => patientEvolution[key].questions)
      .map((questions) => {
        let aux = 0;
        questions.forEach((question) => {
          switch (question['question-id']) {
            case '3a':
              aux += question.answer;
              break;
            default:
              break;
          }
        });
        return aux;
      });
    const newStage = newDates.map((date) => getClosestStage(patientData, date));
    // const newStage = newDates.map((date) => {
    // let stage;
    // let lastDate = new Date(0); // Smallest date possible
    // // Get dates when stage was changed
    // const stagesDates = patientData.stage.map((el) => new Date(Object.keys(el)[0])); // Check when date format is known
    // // Get stage of the date when the stage changed closest to the questionnaire date
    // stagesDates.forEach((stageDate) => {
    //   if (stageDate <= date && stageDate > lastDate) {
    //     lastDate = stageDate;
    //     [stage] = Object.values(
    //       patientData.stage.filter((el) => Object.keys(el)[0] === stageDate.toISOString())[0]
    //     );
    //   }
    // });
    // console.log(stage);
    // return stage;
    // });
    const newTimeDiagnosis = Object.keys(patientEvolution)
      .map((key) => patientEvolution[key].questions)
      .map((questions) => {
        let aux = 0;
        questions.forEach((question) => {
          switch (question['question-id']) {
            case '3b':
              aux += question.answer;
              break;
            default:
              break;
          }
        });
        return aux;
      });
    const newReasonCheck = Object.keys(patientEvolution)
      .map((key) => patientEvolution[key].questions)
      .map((questions) => {
        let aux = 0;
        questions.forEach((question) => {
          switch (question['question-id']) {
            case '4a':
              aux += question.answer;
              break;
            default:
              break;
          }
        });
        return aux;
      });
    setLastCheck(newLastCheck);
    setStage(newStage);
    setTimeDiagnosis(newTimeDiagnosis);
    setReasonCheck(newReasonCheck);
  }, [patientEvolution]);

  const getChartData = (lastCheck, timeDiagnosis, reasonCheck, stage) => [
    {
      name: 'Última consulta',
      data: lastCheck,
      type: 'line'
    },
    {
      name: 'Tiempo desde el diagnóstico',
      data: timeDiagnosis,
      type: 'line'
    },
    {
      name: 'Motivo Consulta',
      data: reasonCheck,
      type: 'line'
    },
    {
      name: 'Estadio',
      data: stage,
      type: 'line'
    }
  ];

  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true
        }
      }
    },
    colors: [COLOR_LAST_CHECK, COLOR_TIME_DIAGNOSIS, COLOR_REASON, COLOR_STAGE],
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    xaxis: {
      title: {
        text: 'Fecha de cuestionario'
      },
      type: 'categories',
      categories: datesStr
    },
    yaxis: {
      title: {
        text: 'Puntuación'
      },
      max: 4,
      tickAmount: 4,
      labels: {
        minWidth: 10
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y}`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card sx={{ mt: 7, mb: 5 }}>
      <CardHeader title="Evolución paciente" subheader="Enfermedad / Consulta" />
      <Box id="evolution" sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={getChartData(lastCheck, timeDiagnosis, reasonCheck, stage)}
          options={chartOptions}
          height={364}
        />
        <Button
          variant="contained"
          color="secondary"
          size="small"
          sx={{ mb: 3 }}
          onClick={() => {
            const newMoreInfo = !moreInfo;
            setMoreInfo(newMoreInfo);
          }}
        >
          Más información
        </Button>
        <Collapse in={moreInfo}>
          <Typography variant="body2" color="text.secondary">
            Estadio de la enfermedad: (I: 1, II: 2, III: 3, IV: 4)
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Tiempo desde el diagnóstico: (Menos de 1 mes: 1, Entre 1 y 3 meses: 2, Entre 3 y 6
            meses: 3, Hace más de 6 meses: 4)
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Última consulta: (Menos de 1 mes: 1, Entre 1 y 3 meses: 2, Entre 3 y 6 meses: 3, Hace
            más de 6 meses: 4)
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Motivo de la consulta: (Ingreso hospitalario: 1, Revisión rutinaria: 2, Tratamiento: 3,
            La aparición de un imprevisto: 4)
          </Typography>
        </Collapse>
      </Box>
    </Card>
  );
}
