import { LoadingButton } from '@mui/lab';
// material
import { Stack, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
// import { Icon } from '@iconify/react';
// import { useFormik, Form, FormikProvider } from 'formik';
// import eyeFill from '@iconify/icons-eva/eye-fill';
// import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function RegisterDoctorForm(props) {
  const { doctorData, onSubmit } = props;
  // console.log(doctorData?.specialty);

  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  const [hospital, setHospital] = useState(doctorData?.hospital || '');
  const [specialty, setSpecialty] = useState(doctorData?.specialty || '');
  const [psychologicalHelp, setPsychologicalHelp] = useState(
    (doctorData && doctorData['psychological-approach']) || null
  );
  const [questionaireFrequency, setQuestionaireFrequency] = useState(
    (doctorData && doctorData['used-resources'].questionnaires) || null
  );
  const [interviewsFrequency, setInterviewsFrequency] = useState(
    (doctorData && doctorData['used-resources'].interviews) || null
  );
  const [psychooncologistFrequency, setPsychooncologistFrequency] = useState(
    (doctorData && doctorData['used-resources'].derivation) || null
  );
  const [usageSituations, setUsageSituations] = useState(doctorData?.situations || null);
  // Optional data
  const [contactPerson, setContactPerson] = useState(doctorData?.contact?.name || '');
  const [contactPhone, setContactPhone] = useState(doctorData?.contact?.phone || '');
  const [contactEmail, setContactEmail] = useState(doctorData?.contact?.email || '');
  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  const [errorMessagePhone, setErrorMessagePhone] = useState('');
  // Terms agreement
  const [termsAgreed, setTermsAgreed] = useState(false);

  const regexEmail = new RegExp('^.+@.+..+$');
  const regexTel = new RegExp('^[0-9]{9}$');

  useEffect(() => {
    if (doctorData !== null) {
      setHospital(doctorData?.hospital || '');
      setSpecialty(doctorData?.specialty || '');
      setPsychologicalHelp(`${doctorData['psychological-approach']}` || null);
      console.log(doctorData['psychological-approach']);
      setQuestionaireFrequency(
        (doctorData && `${doctorData['used-resources'].questionnaires}`) || null
      );
      setInterviewsFrequency((doctorData && `${doctorData['used-resources'].interviews}`) || null);
      setPsychooncologistFrequency(
        (doctorData && `${doctorData['used-resources'].derivation}`) || null
      );
      setUsageSituations(`${doctorData?.situations}` || null);
      setContactPerson(doctorData?.contact?.name || '');
      setContactPhone(doctorData?.contact?.phone || '');
      setContactEmail(doctorData?.contact?.email || '');
      setTermsAgreed(true);
    }
    // console.log(psychologicalHelp);
  }, [doctorData]);

  // const RegisterSchema = Yup.object().shape({
  //   firstName: Yup.string()
  //     .min(2, 'Too Short!')
  //     .max(50, 'Too Long!')
  //     .required('First name required'),
  //   lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
  //   email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  //   password: Yup.string().required('Password is required')
  // });

  // const formik = useFormik({
  //   initialValues: {
  //     firstName: '',
  //     lastName: '',
  //     email: '',
  //     password: ''
  //   },
  //   validationSchema: RegisterSchema,
  //   onSubmit: () => {
  //     navigate('/dashboard', { replace: true });
  //   }
  // });

  // const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    switch (name) {
      case 'Hospital':
        setHospital(value);
        break;
      case 'Especialidad':
        setSpecialty(value);
        break;
      case 'AbordajePsicologico':
        setPsychologicalHelp(value);
        break;
      case 'FrecuenciaCuestionarios':
        setQuestionaireFrequency(value);
        break;
      case 'FrecuenciaEntrevistas':
        setInterviewsFrequency(value);
        break;
      case 'FrecuenciaPsicooncologo':
        setPsychooncologistFrequency(value);
        break;
      case 'PersonaContacto':
        setContactPerson(value);
        break;
      case 'TelContacto':
        setContactPhone(value);
        break;
      case 'EmailContacto':
        setContactEmail(value);
        break;
      case 'SituacionesUso':
        setUsageSituations(value);
        break;
      case 'Terminos':
        setTermsAgreed(checked);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (contactEmail && !regexEmail.test(contactEmail)) {
      setErrorMessageEmail('No es un email válido');
    }
  }, [contactEmail]);

  useEffect(() => {
    if ((!contactEmail || regexEmail.test(contactEmail)) && errorMessageEmail) {
      setErrorMessageEmail('');
    }
  }, [contactEmail, errorMessageEmail]);

  useEffect(() => {
    if (contactPhone && !regexTel.test(contactPhone)) {
      setErrorMessagePhone('No es un teléfono válido');
    }
  }, [contactPhone]);

  useEffect(() => {
    if ((!contactPhone || regexTel.test(contactPhone)) && errorMessagePhone) {
      setErrorMessagePhone('');
    }
  }, [contactPhone, errorMessagePhone]);

  return (
    <Stack spacing={3}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <FormControl fullWidth>
          <InputLabel id="id-hospital">Hospital</InputLabel>
          <Select
            disabled={doctorData !== null}
            fullWidth
            name="Hospital"
            label="Hospital"
            value={hospital}
            helperText="Required"
            onChange={handleChange}
          >
            <MenuItem value="H. CENTRAL DE LA CRUZ ROJA">H. CENTRAL DE LA CRUZ ROJA</MenuItem>
            <MenuItem value="H. DOCTOR RODRÍGUEZ LAFORA">H. DOCTOR RODRÍGUEZ LAFORA</MenuItem>
            <MenuItem value="H. EL ESCORIAL">H. EL ESCORIAL</MenuItem>
            <MenuItem value="H. G. U. GREGORIO MARAÑÓN">H. G. U. GREGORIO MARAÑÓN</MenuItem>
            <MenuItem value="H. GUADARRAMA">H. GUADARRAMA</MenuItem>
            <MenuItem value="H. I. U. NIÑO JESÚS">H. I. U. NIÑO JESÚS</MenuItem>
            <MenuItem value="H. LA FUENFRÍA">H. LA FUENFRÍA</MenuItem>
            <MenuItem value="H. U. 12 DE OCTUBRE">H. U. 12 DE OCTUBRE</MenuItem>
            <MenuItem value="H. U. CLÍNICO SAN CARLOS">H. U. CLÍNICO SAN CARLOS</MenuItem>
            <MenuItem value="H. U. DE FUENLABRADA">H. U. DE FUENLABRADA</MenuItem>
            <MenuItem value="H. U. DE GETAFE">H. U. DE GETAFE</MenuItem>
            <MenuItem value="H. U. DE LA PRINCESA">H. U. DE LA PRINCESA</MenuItem>
            <MenuItem value="H. U. DE MÓSTOLES">H. U. DE MÓSTOLES</MenuItem>
            <MenuItem value="H. U. DEL HENARES">H. U. DEL HENARES</MenuItem>
            <MenuItem value="H. U. DEL SURESTE">H. U. DEL SURESTE</MenuItem>
            <MenuItem value="H. U. DEL TAJO">H. U. DEL TAJO</MenuItem>
            <MenuItem value="H. U. FUNDACIÓN ALCORCÓN">H. U. FUNDACIÓN ALCORCÓN</MenuItem>
            <MenuItem value="H. U. INFANTA CRISTINA">H. U. INFANTA CRISTINA</MenuItem>
            <MenuItem value="H. U. INFANTA LEONOR">H. U. INFANTA LEONOR</MenuItem>
            <MenuItem value="H. U. INFANTA SOFÍA">H. U. INFANTA SOFÍA</MenuItem>
            <MenuItem value="H. U. JOSÉ GERMAIN">H. U. JOSÉ GERMAIN</MenuItem>
            <MenuItem value="H. U. LA PAZ">H. U. LA PAZ</MenuItem>
            <MenuItem value="H. U. PRÍNCIPE DE ASTURIAS">H. U. PRÍNCIPE DE ASTURIAS</MenuItem>
            <MenuItem value=" H. U. PUERTA DE HIERRO MAJADAHONDA">
              H. U. PUERTA DE HIERRO MAJADAHONDA
            </MenuItem>
            <MenuItem value="H. U. RAMÓN Y CAJAL">H. U. RAMÓN Y CAJAL</MenuItem>
            <MenuItem value="H. U. SANTA CRISTINA">H. U. SANTA CRISTINA</MenuItem>
            <MenuItem value="H. U. SEVERO OCHOA">H. U. SEVERO OCHOA</MenuItem>
            <MenuItem value="H. VIRGEN DE LA POVEDA">H. VIRGEN DE LA POVEDA</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="id-especialidad">Especialidad</InputLabel>
          <Select
            disabled={doctorData !== null}
            fullWidth
            name="Especialidad"
            label="Especialidad"
            value={specialty}
            helperText="Required"
            onChange={handleChange}
          >
            <MenuItem value="0">Oncología médica</MenuItem>
            <MenuItem value="1">Oncología terápica</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <FormControl component="fieldset" disabled={doctorData !== null}>
        <FormLabel component="legend" sx={{ fontWeight: 'bold' }}>
          Hasta ahora, ¿en qué grado abordabas la esfera psicológica de tus pacientes?
        </FormLabel>
        <FormLabel component="legend" sx={{ fontWeight: 'bold' }}>
          (Presencia de ansiedad, depresión, estilo de afrontamiento de la enfermedad)
        </FormLabel>
        <RadioGroup
          row
          name="AbordajePsicologico"
          aria-label="abordajePsicologico"
          value={psychologicalHelp}
          onChange={handleChange}
        >
          <FormControlLabel value="0" control={<Radio />} label="0 (nada)" />
          <FormControlLabel value="1" control={<Radio />} label="1" />
          <FormControlLabel value="2" control={<Radio />} label="2" />
          <FormControlLabel value="3" control={<Radio />} label="3" />
          <FormControlLabel value="4" control={<Radio />} label="4" />
          <FormControlLabel value="5" control={<Radio />} label="5" />
          <FormControlLabel value="6" control={<Radio />} label="6" />
          <FormControlLabel value="7" control={<Radio />} label="7" />
          <FormControlLabel value="8" control={<Radio />} label="8" />
          <FormControlLabel value="9" control={<Radio />} label="9" />
          <FormControlLabel value="10" control={<Radio />} label="10 (todo)" />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" disabled={doctorData !== null}>
        <FormLabel component="legend" sx={{ fontWeight: 'bold' }}>
          Valora de 0 a 10 con qué frecuencia utilizabas los siguientes recursos para abordar dicha
          esfera psicológica.
        </FormLabel>
        <FormLabel component="legend">Cuestionarios estandarizados:</FormLabel>
        <RadioGroup
          row
          name="FrecuenciaCuestionarios"
          aria-label="frecuenciaCuestionarios"
          value={questionaireFrequency}
          onChange={handleChange}
        >
          <FormControlLabel value="0" control={<Radio />} label="0 (nunca)" />
          <FormControlLabel value="1" control={<Radio />} label="1" />
          <FormControlLabel value="2" control={<Radio />} label="2" />
          <FormControlLabel value="3" control={<Radio />} label="3" />
          <FormControlLabel value="4" control={<Radio />} label="4" />
          <FormControlLabel value="5" control={<Radio />} label="5" />
          <FormControlLabel value="6" control={<Radio />} label="6" />
          <FormControlLabel value="7" control={<Radio />} label="7" />
          <FormControlLabel value="8" control={<Radio />} label="8" />
          <FormControlLabel value="9" control={<Radio />} label="9" />
          <FormControlLabel value="10" control={<Radio />} label="10 (siempre)" />
        </RadioGroup>
        <FormLabel component="legend">Preguntando directamente (entrevistas):</FormLabel>
        <RadioGroup
          row
          name="FrecuenciaEntrevistas"
          aria-label="frecuenciaEntrevistas"
          value={interviewsFrequency}
          onChange={handleChange}
        >
          <FormControlLabel value="0" control={<Radio />} label="0 (nunca)" />
          <FormControlLabel value="1" control={<Radio />} label="1" />
          <FormControlLabel value="2" control={<Radio />} label="2" />
          <FormControlLabel value="3" control={<Radio />} label="3" />
          <FormControlLabel value="4" control={<Radio />} label="4" />
          <FormControlLabel value="5" control={<Radio />} label="5" />
          <FormControlLabel value="6" control={<Radio />} label="6" />
          <FormControlLabel value="7" control={<Radio />} label="7" />
          <FormControlLabel value="8" control={<Radio />} label="8" />
          <FormControlLabel value="9" control={<Radio />} label="9" />
          <FormControlLabel value="10" control={<Radio />} label="10 (siempre)" />
        </RadioGroup>
        <FormLabel component="legend">Derivando al psico-oncólogo:</FormLabel>
        <RadioGroup
          row
          name="FrecuenciaPsicooncologo"
          aria-label="frecuenciaPsicooncologo"
          value={psychooncologistFrequency}
          onChange={handleChange}
        >
          <FormControlLabel value="0" control={<Radio />} label="0 (nunca)" />
          <FormControlLabel value="1" control={<Radio />} label="1" />
          <FormControlLabel value="2" control={<Radio />} label="2" />
          <FormControlLabel value="3" control={<Radio />} label="3" />
          <FormControlLabel value="4" control={<Radio />} label="4" />
          <FormControlLabel value="5" control={<Radio />} label="5" />
          <FormControlLabel value="6" control={<Radio />} label="6" />
          <FormControlLabel value="7" control={<Radio />} label="7" />
          <FormControlLabel value="8" control={<Radio />} label="8" />
          <FormControlLabel value="9" control={<Radio />} label="9" />
          <FormControlLabel value="10" control={<Radio />} label="10 (siempre)" />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" disabled={doctorData !== null}>
        <FormLabel component="legend" sx={{ fontWeight: 'bold' }}>
          Hasta ahora, ¿en qué grado abordabas la esfera psicológica de tus pacientes?
        </FormLabel>
        <FormLabel component="legend" sx={{ fontWeight: 'bold' }}>
          (Presencia de ansiedad, depresión, estilo de afrontamiento de la enfermedad)
        </FormLabel>
        <RadioGroup
          name="SituacionesUso"
          aria-label="situacionesUso"
          value={usageSituations}
          onChange={handleChange}
        >
          <FormControlLabel
            value="0"
            control={<Radio />}
            label="Al inicio, al dar el diagnóstico"
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="En momentos que se consideraban complicados"
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label="Al observar que el paciente experimentaba una fuerte emocionalidad"
          />
          <FormControlLabel value="3" control={<Radio />} label="En cada consulta" />
          <FormControlLabel
            value="4"
            control={<Radio />}
            label="Con cierta regularidad (entre 3 y 6 meses)"
          />
        </RadioGroup>
      </FormControl>

      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Campos opcionales
      </Typography>
      <Divider />
      <TextField
        disabled={doctorData !== null}
        name="PersonaContacto"
        value={contactPerson}
        onChange={handleChange}
        label="Persona de contacto"
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextField
          disabled={doctorData !== null}
          name="TelContacto"
          value={contactPhone}
          onChange={handleChange}
          label="Teléfono de contacto"
          helperText={errorMessagePhone}
          error={contactPhone && !regexTel.test(contactPhone)}
          fullWidth
        />
        <TextField
          disabled={doctorData !== null}
          name="EmailContacto"
          value={contactEmail}
          type="email"
          onChange={handleChange}
          label="Email de contacto"
          helperText={errorMessageEmail}
          error={contactEmail && !regexEmail.test(contactEmail)}
          fullWidth
        />
      </Stack>

      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <Checkbox
          disabled={doctorData !== null}
          name="Terminos"
          checked={termsAgreed}
          onChange={handleChange}
        />
        <Typography
          variant="body2"
          align="center"
          sx={{ color: 'text.secondary', mt: 3, textAlign: 'center', pt: 1 }}
        >
          He leído y acepto la presente&nbsp;
          <Link underline="always" sx={{ color: 'text.primary' }} href="/PoliticaPrivacidad.pdf">
            política de privacidad
          </Link>
          &nbsp;y los&nbsp;
          <Link
            underline="always"
            sx={{ color: 'text.primary' }}
            href="https://telegra.ph/Aviso-legal---ADAF-Chatbot-06-14"
          >
            términos y condiciones
          </Link>
          .
        </Typography>
      </Stack>
      {doctorData === null ? (
        <LoadingButton
          sx={{
            maxWidth: 300
          }}
          size="large"
          type="submit"
          variant="contained"
          loading={submitted && doctorData === null}
          disabled={
            hospital === '' ||
            specialty === '' ||
            psychologicalHelp === null ||
            questionaireFrequency === null ||
            interviewsFrequency === null ||
            psychooncologistFrequency === null ||
            usageSituations === null ||
            !termsAgreed ||
            errorMessagePhone !== '' ||
            errorMessageEmail !== ''
          }
          onClick={() => {
            setSubmitted(true);
            onSubmit({
              specialty: parseInt(specialty, 10),
              contact: {
                phone: contactPhone,
                name: contactPerson,
                email: contactEmail
              },
              hospital,
              'psychological-approach': parseInt(psychologicalHelp, 10),
              'used-resources': {
                questionnaires: parseInt(questionaireFrequency, 10),
                derivation: parseInt(psychooncologistFrequency, 10),
                interviews: parseInt(interviewsFrequency, 10)
              },
              situations: parseInt(usageSituations, 10)
            });
          }}
        >
          Guardar
        </LoadingButton>
      ) : null}
    </Stack>
  );
}
