import { useEffect, useState } from 'react';
// import { Icon } from '@iconify/react';
// import { useFormik, Form, FormikProvider } from 'formik';
// import eyeFill from '@iconify/icons-eva/eye-fill';
// import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, Snackbar, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

// ----------------------------------------------------------------------

export default function UpdatePatientForm(props) {
  // const navigate = useNavigate();
  const { onSubmit, patientData, updated } = props;
  // const [showPassword, setShowPassword] = useState(false);
  // const [nHistoria, setNHistoria] = useState('');// Generated in backend and presented to the doctor
  const [submitted, setSubmitted] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [gender, setGender] = useState('');
  const [birth, setBirth] = useState(null);
  const [diagnosis, setDiagnosis] = useState('');
  const [stage, setStage] = useState('');
  const [receivingAttention, setReceivingAttention] = useState(null);
  const [inAdaf, setInAdaf] = useState('');

  useEffect(() => {
    if (patientData) {
      setGender(patientData.gender);
      setBirth(patientData.birth);
      setDiagnosis(`${patientData.diagnosis}`);
      setStage(
        `${
          Object.keys(patientData.stage[patientData.stage.length - 1]).map(
            (key) => patientData.stage[patientData.stage.length - 1][key]
          )[0]
        }`
      );
      setReceivingAttention(patientData['receiving-attention'] ? 'true' : 'false');
      setInAdaf(patientData.reason);
    }
  }, [patientData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'Sexo':
        setGender(value);
        break;
      case 'FechaNacimiento':
        setBirth(value);
        break;
      case 'Diagnostico':
        setDiagnosis(value);
        break;
      case 'EstadioEnfermedad':
        setStage(value);
        break;
      case 'RecibeAtencion':
        setReceivingAttention(value);
        break;
      case 'AbandonaAdaf':
        setInAdaf(value);
        break;
      default:
        break;
    }
  };

  return (
    <Stack spacing={3}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <FormControl fullWidth>
          <InputLabel id="id-sexo">Sexo</InputLabel>
          <Select
            fullWidth
            name="Sexo"
            label="Sexo"
            value={gender}
            helperText="Required"
            onChange={handleChange}
          >
            <MenuItem value="male">Hombre</MenuItem>
            <MenuItem value="female">Mujer</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="id-nacimiento"
          name="FechaNacimiento"
          label="FechaNacimiento"
          type="date"
          value={birth}
          onChange={handleChange}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <FormControl fullWidth>
          <InputLabel id="id-diagnostico">Tipo de cáncer</InputLabel>
          <Select
            fullWidth
            name="Diagnostico"
            label="Tipo de cáncer"
            value={diagnosis}
            helperText="Required"
            onChange={handleChange}
          >
            <MenuItem value="0">Pancreas</MenuItem>
            <MenuItem value="1">Pulmón</MenuItem>
            <MenuItem value="2">Cabeza y cuello</MenuItem>
            <MenuItem value="3">Colon y recto</MenuItem>
            <MenuItem value="4">Hígado y conducto biliar</MenuItem>
            <MenuItem value="5">Ginecológicos</MenuItem>
            <MenuItem value="6">Linfoma</MenuItem>
            <MenuItem value="7">Mieloma</MenuItem>
            <MenuItem value="8">Ovario</MenuItem>
            <MenuItem value="9">Piel</MenuItem>
            <MenuItem value="10">Próstata</MenuItem>
            <MenuItem value="11">Renal (células renales y de la pelvis renal)</MenuItem>
            <MenuItem value="12">Tiroide</MenuItem>
            <MenuItem value="13">Útero</MenuItem>
            <MenuItem value="14">Vagina y vulva</MenuItem>
            <MenuItem value="15">Vejiga</MenuItem>
            <MenuItem value="16">Cuello uterino</MenuItem>
            <MenuItem value="17">Mama</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="id-estadio">Estadio</InputLabel>
          <Select
            fullWidth
            name="EstadioEnfermedad"
            label="Estadio"
            value={stage}
            helperText="Required"
            onChange={handleChange}
          >
            <MenuItem value="1">I</MenuItem>
            <MenuItem value="2">II</MenuItem>
            <MenuItem value="3">III</MenuItem>
            <MenuItem value="4">IV</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <FormLabel component="legend" sx={{ fontWeight: 'bold' }}>
            ¿En el momento de la consulta está recibiendo atención psicológica?
          </FormLabel>
          <RadioGroup
            row
            name="RecibeAtencion"
            aria-label="recibeAtencion"
            value={receivingAttention}
            onChange={handleChange}
          >
            <FormControlLabel value="true" control={<Radio />} label="Sí" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Stack>
      <Typography component="legend" sx={{ fontWeight: 'bold' }}>
        Paciente, ¿ha salido de ADAF?
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="id-estadio">Motivo</InputLabel>
        <Select
          fullWidth
          name="AbandonaAdaf"
          label="Motivo"
          value={inAdaf}
          helperText="Required"
          onChange={handleChange}
        >
          <MenuItem value="No">No</MenuItem>
          <MenuItem value="Fallecimiento por cáncer">Sí fallecimiento por cáncer</MenuItem>
          <MenuItem value="Ha dejado de participar">Sí, ha dejado de participar</MenuItem>
        </Select>
      </FormControl>

      <Divider />
      <LoadingButton
        sx={{
          maxWidth: 300
        }}
        size="large"
        type="submit"
        variant="contained"
        color="secondary"
        loading={submitted && !updated}
        disabled={
          gender === '' ||
          birth === null ||
          diagnosis === '' ||
          stage === null ||
          receivingAttention === null
        }
        onClick={() => {
          setSubmitted(true);
          onSubmit({
            gender,
            birth,
            diagnosis: parseInt(diagnosis, 10),
            'receiving-attention': receivingAttention === 'true',
            'in-adaf': inAdaf === '',
            reason: inAdaf,
            stage: parseInt(stage, 10)
          });
          setSnackbar(true);
        }}
      >
        Guardar
      </LoadingButton>
      <Snackbar
        sx={{ zIndex: '1' }}
        open={snackbar}
        autoHideDuration={4000}
        onClose={() => setSnackbar(false)}
      >
        <Alert
          onClose={() => setSnackbar(false)}
          severity="success"
          sx={{ zIndex: '1', width: '100%' }}
        >
          Paciente actualizado correctamente
        </Alert>
      </Snackbar>
    </Stack>
  );
}
