import {
  DataGrid,
  GridColumnHeaderSeparator,
  GridSeparatorIcon,
  GridToolbar
} from '@mui/x-data-grid';
import { Box } from '@mui/system';

const columns = [
  { field: 'id', headerName: 'ID', width: 175, headerClassName: 'super-app-theme--header' },
  {
    field: 'gender',
    headerName: 'Género',
    headerClassName: 'super-app-theme--header',
    description: 'ID de historial del paciente',
    width: 150,
    editable: false
  },
  {
    field: 'birth',
    headerName: 'Fecha de nacimiento',
    headerClassName: 'super-app-theme--header',
    description: 'Fecha de nacimiento del paciente',
    type: 'date',
    width: 190,
    editable: false,
    valueFormatter: ({ value }) => {
      const birthDate = new Date(value);
      return (
        birthDate && `${birthDate.getDate()}/${birthDate.getMonth() + 1}/${birthDate.getFullYear()}`
      );
    }
  },
  {
    field: 'diagnosis',
    headerName: 'Diagnóstico',
    headerClassName: 'super-app-theme--header',
    description: 'Tipo de cáncer del paciente',
    width: 150,
    editable: false
  },
  {
    field: 'stage',
    headerName: 'Estadio',
    headerClassName: 'super-app-theme--header',
    description: 'Estadio de la evolución del cáncer en el paciente',
    width: 150,
    editable: false,
    valueGetter: ({ value }) => {
      const stage = Object.keys(value[value.length - 1]).map(
        (key) => value[value.length - 1][key]
      )[0];
      // const dateStr = Object.keys(value[0])[0];
      return stage;
    }
  },
  {
    field: 'receiving-attention',
    headerName: 'Recibe atención',
    headerClassName: 'super-app-theme--header',
    description: 'El paciente está recibiendo asistencia psicológica',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value ? 'Sí' : 'No')
  },
  {
    field: 'in-adaf',
    headerName: 'En ADAF',
    headerClassName: 'super-app-theme--header',
    description: 'El paciente sigue participando en ADAF',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value ? 'Sí' : 'No')
  },
  {
    field: 'reason',
    headerName: 'Razón abandono ADAF',
    headerClassName: 'super-app-theme--header',
    description: 'Razón por la cual el paciente ya no participa en ADAF',
    width: 200,
    editable: false,
    valueFormatter: ({ value }) => (value === '' ? '-' : value)
  }
];

export default function DataTableOneSelection(props) {
  const { patients, selectionModel, onSelectionChange } = props;

  return (
    <Box
      sx={{
        minHeight: 400,
        color: '#ffffff',
        border: 0,
        borderColor: '#000',
        '& .super-app-theme--header': {
          backgroundColor: 'rgba(145, 158, 171, 0.16)'
        },
        '& .MuiDataGrid-iconSeparator': {
          display: 'none'
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell, .MuiDataGrid-columnHeaders, .MuiDataGrid-row, .MuiDataGrid-root':
          {
            border: `0px`
          }
      }}
    >
      <DataGrid
        sx={{ color: '#ffffff', minHeight: 400 }}
        rows={patients}
        columns={columns}
        pageSize={100}
        components={{
          Toolbar: GridToolbar
        }}
        rowsPerPageOptions={[100]}
        disableMultipleSelection
        onSelectionModelChange={onSelectionChange}
        selectionModel={selectionModel}
      />
    </Box>
  );
}
