import { Avatar, Box, Drawer, Link, Typography } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import { palette } from '@mui/system';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { MHidden } from '../../components/@material-extend';
// components
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
import AppContext from '../../context/AppContext';
import { getCurrentUserInfo } from '../../domain/hooks';
import account from '../../_mocks_/account';
//
import sidebarConfig from './SidebarConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  zIndex: '0',
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[800]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const appContext = useContext(AppContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    const getUserInfo = async () => {
      const _userInfo = await getCurrentUserInfo();
      setUserInfo(_userInfo);
    };
    getUserInfo();
  }, []);

  const routesByRole = ({ role }) => {
    if (appContext.forceRegister) {
      return sidebarConfig.filter((i) => i.path === '/dashboard/profile');
    }
    switch (role) {
      case 'Doctor':
        return sidebarConfig.filter(
          (i) => !['/dashboard/analysis/researchers', '/dashboard/register/users'].includes(i.path)
        );
      case 'Investigador':
        return sidebarConfig.filter(
          (i) =>
            ![
              '/dashboard/register/users',
              '/dashboard/analysis/patients',
              '/dashboard/profile'
            ].includes(i.path)
        );
      case 'Administrador':
        return sidebarConfig;
      default:
        return sidebarConfig;
    }
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box
        sx={{ px: 2.5, py: 3, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}
      >
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {userInfo?.attributes['custom:fullname'] ??
                  userInfo?.attributes?.email?.split('@')[0]}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {appContext.group}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={routesByRole({ role: appContext?.group })} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
            backgroundColor: '#161C24',
            borderStyle: 'hidden dashed hidden hidden'
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              backgroundColor: '#161C24',
              borderStyle: 'hidden dashed hidden hidden',
              borderColor: '#919EAB'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
