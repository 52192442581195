import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useState } from 'react';

export default function SatisfactionForm(props) {
  const { onSubmit } = props;

  const [submitted, setSubmitted] = useState(false);
  const [clinic, setClinic] = useState('');
  const [patientsLife, setPatientsLife] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'PracticaClinica':
        setClinic(value);
        break;
      case 'CalidadVida':
        setPatientsLife(value);
        break;
      default:
        break;
    }
  };

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <FormControl component="fieldset">
        <FormLabel component="legend" sx={{ fontWeight: 'bold' }}>
          Tu práctica clínica:
        </FormLabel>
        <RadioGroup
          row
          name="PracticaClinica"
          aria-label="PracticaClinica"
          value={clinic}
          onChange={handleChange}
        >
          <FormControlLabel value="0" control={<Radio />} label="0 (poca)" />
          <FormControlLabel value="1" control={<Radio />} label="1" />
          <FormControlLabel value="2" control={<Radio />} label="2" />
          <FormControlLabel value="3" control={<Radio />} label="3" />
          <FormControlLabel value="4" control={<Radio />} label="4" />
          <FormControlLabel value="5" control={<Radio />} label="5" />
          <FormControlLabel value="6" control={<Radio />} label="6" />
          <FormControlLabel value="7" control={<Radio />} label="7" />
          <FormControlLabel value="8" control={<Radio />} label="8" />
          <FormControlLabel value="9" control={<Radio />} label="9" />
          <FormControlLabel value="10" control={<Radio />} label="10 (mucha)" />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel component="legend" sx={{ fontWeight: 'bold' }}>
          La calidad de vida del paciente:
        </FormLabel>
        <RadioGroup
          row
          name="CalidadVida"
          aria-label="CalidadVida"
          value={patientsLife}
          onChange={handleChange}
        >
          <FormControlLabel value="0" control={<Radio />} label="0 (poca)" />
          <FormControlLabel value="1" control={<Radio />} label="1" />
          <FormControlLabel value="2" control={<Radio />} label="2" />
          <FormControlLabel value="3" control={<Radio />} label="3" />
          <FormControlLabel value="4" control={<Radio />} label="4" />
          <FormControlLabel value="5" control={<Radio />} label="5" />
          <FormControlLabel value="6" control={<Radio />} label="6" />
          <FormControlLabel value="7" control={<Radio />} label="7" />
          <FormControlLabel value="8" control={<Radio />} label="8" />
          <FormControlLabel value="9" control={<Radio />} label="9" />
          <FormControlLabel value="10" control={<Radio />} label="10 (mucha)" />
        </RadioGroup>
      </FormControl>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={submitted}
        disabled={patientsLife === '' || clinic === ''}
        onClick={() => {
          setSubmitted(true);
          onSubmit({
            clinic,
            'patients-life': patientsLife
          });
        }}
      >
        Guardar
      </LoadingButton>
    </Stack>
  );
}
