import { Stack, Typography, Button, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// QR generator
import QRCode from 'react-qr-code';
// PDF renderer
// import {
//   Page,
//   Text,
//   View,
//   Document,
//   StyleSheet,
//   Image,
//   Link as LinkPDF,
//   PDFViewer
// } from '@react-pdf/renderer';

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'column',
//     justifyContent: 'top',
//     alignContent: 'center',
//     backgroundColor: '#E4E4E4'
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// });

// const QRDocument = (dataURL) => (
//   <Document>
//     <Page key="1" size="A4" style={styles.page}>
//       <View style={styles.section}>
//         <Image allowDangerousPaths src={dataURL} />
//         <Text>URI del chatbot de Telegram:</Text>
//         <LinkPDF src="https://saturnolabs.com/" />
//       </View>
//     </Page>
//   </Document>
// );

export default function QRViewer(props) {
  // const [instance, updateInstance] = usePDF({
  //   document: () => {
  //     QRDocument(document.getElementById('id-qr').toDataURL());
  //   }
  // });
  const { adafId, handlePrint } = props;

  return (
    <Stack spacing={3}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <QRCode
          id="id-qr"
          value={`https://t.me/adafcbot?start=${adafId}`}
          includeMargin
          size={256}
        />
        <Stack spacing={2} sx={{ maxWidth: '30%' }}>
          <Typography component="legend">URI del chatbot en telegram:</Typography>
          <Typography component="legend">
            <Link
              href={`https://t.me/adafcbot?start=${adafId}`}
            >{`https://t.me/adafcbot?start=${adafId}`}</Link>
          </Typography>
          <Typography component="legend">Código del paciente: {adafId}</Typography>
          <Typography component="legend">
            Por favor imprima este código y adjúntelo a la documentación de ADAF entregada al
            paciente.
          </Typography>
          <Typography component="legend">Gracias.</Typography>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handlePrint}
          >
            Imprimir
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
}
