import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      src="/static/combinado.svg"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        width: 150,
        height: 40,
        ...sx
      }}
    />
  );
}
