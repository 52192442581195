import { useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { forgotPasswordRequestCode, changePassword } from '../../../domain/hooks';

// ----------------------------------------------------------------------

function RecoveryForm() {
  const navigate = useNavigate();
  const [sentCode, setSentCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const RecoveryCodeFormSchema = Yup.object().shape({
    email: Yup.string()
      .email('El correo debe ser una dirección válida')
      .required('Es necesario un correo')
  });

  const NewPasswordFormSchema = Yup.object().shape({
    email: Yup.string()
      .email('El correo debe ser una dirección válida')
      .required('Es necesario un correo'),
    password: Yup.string()
      .min(8, 'La contraseña debe tener 8 caracteres o más.')
      .required('La contraseña es obligatoria.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        'Debe contener más de 8 caracteres, una mayúscula, una minúscula y un número.'
      ),
    code: Yup.string().required('Código de recuperación recibido al correo')
  });

  const requestCodeForm = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: RecoveryCodeFormSchema,
    onSubmit: async (values) => {
      await forgotPasswordRequestCode({ email: values.email });
      setSentCode(true);
    }
  });

  const newPasswordForm = useFormik({
    initialValues: {
      email: '',
      password: '',
      code: ''
    },
    validationSchema: NewPasswordFormSchema,
    onSubmit: async (values) => {
      await changePassword({
        email: values.email,
        code: values.code,
        newPassword: values.password
      });
      navigate('/login', { replace: true });
    }
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const {
    errors: errorsRequestCode,
    touched: touchedRequestCode,
    isSubmitting: isSubmittingRequestCode,
    handleSubmit: handleRequestCodeSubmit,
    getFieldProps: getRequestCodeFieldProps
  } = requestCodeForm;

  const {
    errors: errorsNewPassword,
    touched: touchedNewPassword,
    isSubmitting: isSubmittingNewPassword,
    handleSubmit: handleNewPasswordSubmit,
    getFieldProps: getNewPasswordFieldProps
  } = newPasswordForm;

  if (!sentCode)
    return (
      <FormikProvider value={requestCodeForm}>
        <Form autoComplete="off" noValidate onSubmit={handleRequestCodeSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Correo electrónico"
              {...getRequestCodeFieldProps('email')}
              error={Boolean(touchedRequestCode.email && errorsRequestCode.email)}
              helperText={touchedRequestCode.email && errorsRequestCode.email}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmittingRequestCode}
            >
              Recuperar contraseña
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    );

  if (sentCode)
    return (
      <FormikProvider value={newPasswordForm}>
        <Form autoComplete="off" noValidate onSubmit={handleNewPasswordSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Correo electrónico"
              {...getNewPasswordFieldProps('email')}
              error={Boolean(touchedNewPassword.email && errorsNewPassword.email)}
              helperText={touchedNewPassword.email && errorsNewPassword.email}
            />

            <TextField
              fullWidth
              autoComplete="code"
              type="text"
              label="Código"
              {...getNewPasswordFieldProps('code')}
              error={Boolean(touchedNewPassword.code && errorsNewPassword.code)}
              helperText={touchedNewPassword.code && errorsNewPassword.code}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Contraseña"
              {...getNewPasswordFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touchedNewPassword.password && errorsNewPassword.password)}
              helperText={touchedNewPassword.password && errorsNewPassword.password}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmittingNewPassword}
            >
              Cambiar contraseña
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    );
}

export default RecoveryForm;
