import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
// import fileTextFill from '@iconify/icons-eva/file-text-fill';
import barChartOutline from '@iconify/icons-eva/bar-chart-outline';
import personFill from '@iconify/icons-eva/person-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Crear usuarios',
    path: '/dashboard/register/users',
    icon: getIcon(personAddFill)
  },
  {
    title: 'Perfil ADAF',
    path: '/dashboard/profile',
    icon: getIcon(personFill)
  },
  {
    title: 'Registro de pacientes',
    path: '/dashboard/register/patients',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Gestión de pacientes',
    path: '/dashboard/manage/patients',
    icon: getIcon(settings2Fill)
  },
  {
    title: 'Análisis de pacientes',
    path: '/dashboard/analysis/patients',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Análisis de investigadores',
    path: '/dashboard/analysis/researchers',
    icon: getIcon(barChartOutline)
  }

  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
