export const API_DOMAIN = 'https://piktt1rih4.execute-api.eu-west-3.amazonaws.com/v2';
export const COLOR_AFRONT_INDEF = '#f39220';
export const COLOR_ANXIETY = '#52abff';
export const COLOR_DEPRESSION = '#0063bf';
export const COLOR_AFRONT_EVIT = '#f5b76d';
export const COLOR_LAST_CHECK = '#71eb6e';
export const COLOR_TIME_DIAGNOSIS = '#fb221a';
export const COLOR_REASON = '#22821c';
export const COLOR_STAGE = '#f17373';
export const COLOR_NAUDIOS = '#009688';
export const COLOR_BACK_GRAPHS = '#212b36';
export const COLOR_BACK_PROFILE = '#252c35';
export const COLOR_AFRONT_INDEF_TOT = '#f56e00';
export const COLOR_ANXIETY_TOT = '#0084ff';
export const COLOR_DEPRESSION_TOT = '#102fad';
export const COLOR_AFRONT_EVIT_TOT = '#ff9717';
export const COLOR_STAGE_TOT = '#e03636';
