import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'ID', width: 175 },
  {
    field: 'gender',
    headerName: 'Género',
    description: 'ID de historial del paciente',
    width: 100,
    editable: false
  },
  {
    field: 'birth',
    headerName: 'Fecha de nacimiento',
    description: 'Fecha de nacimiento del paciente',
    type: 'date',
    width: 170,
    editable: false,
    valueFormatter: ({ value }) => {
      const birthDate = new Date(value);
      return (
        birthDate && `${birthDate.getDate()}/${birthDate.getMonth() + 1}/${birthDate.getFullYear()}`
      );
    }
  },
  {
    field: 'diagnosis',
    headerName: 'Diagnóstico',
    description: 'Tipo de cáncer del paciente',
    width: 110,
    editable: false
  },
  {
    field: 'stage',
    headerName: 'Estadio',
    description: 'Estadio de la evolución del cáncer en el paciente',
    width: 100,
    editable: false,
    valueGetter: ({ value }) => {
      const stage = Object.keys(value[value.length - 1]).map(
        (key) => value[value.length - 1][key]
      )[0];
      // const dateStr = Object.keys(value[0])[0];
      return stage;
    }
  },
  {
    field: 'last-updated',
    headerName: 'Última actualización',
    description: 'Última vez que el doctor actualizó el paciente',
    width: 180,
    editable: false,
    valueFormatter: ({ value }) => new Date(value).toLocaleDateString('es-ES')
  },
  {
    field: 'receiving-attention',
    headerName: 'Recibe atención',
    description: 'El paciente está recibiendo asistencia psicológica',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value ? 'Sí' : 'No')
  },
  {
    field: 'in-adaf',
    headerName: 'En ADAF',
    description: 'El paciente sigue participando en ADAF',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value ? 'Sí' : 'No')
  },
  {
    field: 'reason',
    headerName: 'Razón abandono ADAF',
    description: 'Razón por la cual el paciente ya no participa en ADAF',
    width: 150,
    editable: false,
    valueFormatter: ({ value }) => (value === '' ? '-' : value)
  }
];

export default function DataTable(props) {
  const { patients, selectionModel, onSelectionChange } = props;

  return (
    <Box
      sx={{
        minHeight: 400,
        color: '#ffffff',
        border: 0,
        borderColor: '#000',
        '& .super-app-theme--header': {
          backgroundColor: 'rgba(145, 158, 171, 0.16)'
        },
        '& .MuiDataGrid-iconSeparator': {
          display: 'none'
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell, .MuiDataGrid-columnHeaders, .MuiDataGrid-row, .MuiDataGrid-root':
          {
            border: `0px`
          }
      }}
    >
      <DataGrid
        sx={{
          color: '#fffff',
          minHeight: 400
        }}
        rows={patients}
        columns={columns}
        pageSize={100}
        components={{
          Toolbar: GridToolbar
        }}
        rowsPerPageOptions={[100]}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={onSelectionChange}
        selectionModel={selectionModel}
      />
    </Box>
  );
}
