import { Box, Divider, Snackbar, Typography, Container } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// material
import { styled } from '@mui/material/styles';
import { forwardRef, useContext, useEffect, useState } from 'react';
import Page from '../components/Page';
// components
import {
  GraphCompAnxiety,
  GraphCompDepression,
  GraphAdaf,
  GraphEvolution,
  GraphToPsychology,
  GraphPatient
} from '../components/_dashboard/graphs';
import GraphCompAfrontEvit from '../components/_dashboard/graphs/GraphCompAfrontEvit';
import GraphCompAfrontIndef from '../components/_dashboard/graphs/GraphCompAfrontIndef';
import { DataDialog } from '../components/_dashboard/patients';
import AppContext from '../context/AppContext';
import { API_URL } from '../properties';

// ----------------------------------------------------------------------

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 0,
  alignContent: 'center',
  display: 'flex',
  minHeight: '50vh',
  flexDirection: 'column',
  justifyContent: 'top',
  padding: theme.spacing(12, 0),
  '& .apexcharts-menu-item': {
    color: '#000'
  }
}));

// ----------------------------------------------------------------------

export default function AnalysisPatients() {
  const appContext = useContext(AppContext);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [patientsWithoutQuests, setPatientsWithoutQuests] = useState([]);
  const [allDoctorPatientsData, setAllDoctorPatientsData] = useState([]);
  const [allDoctorPatientsEvolution, setAllDoctorPatientsEvolution] = useState([]);
  const [allGlobalPatientsData, setAllGlobalPatientsData] = useState([]);
  const [allGlobalPatientsEvolution, setAllGlobalPatientsEvolution] = useState([]);
  const [selectedPatientsData, setSelectedPatientsData] = useState([]);
  const [selectedPatientsEvolution, setSelectedPatientsEvolution] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const onSelectionChange = (newSelection) => {
    // console.log(newSelection);
    setSelectionModel(newSelection);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Retrieve the patients of the doctor
  useEffect(() => {
    const getAllDoctorPatientsData = async () => {
      const res = await fetch(`${API_URL}/v2/patients/doctor?doctor-id=${appContext.email}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: appContext.jwt
        }
      });
      const patients = await res.json();
      setAllDoctorPatientsData(patients);
      // console.log(patients);
    };

    getAllDoctorPatientsData();
  }, [appContext.email]);

  // Retrieve the patients of the study
  useEffect(() => {
    const getAllGlobalPatientsData = async () => {
      const res = await fetch(`${API_URL}/v2/patients/global?investigation=false`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: appContext.jwt
        }
      });
      const patients = await res.json();
      setAllGlobalPatientsData(patients);
      // console.log(patients);
    };

    getAllGlobalPatientsData();
  }, [appContext.jwt]);

  useEffect(() => {
    const getAllDoctorPatientsEvolution = async () => {
      const newPatientsEvolution = [];
      const newPatientsWithoutQuests = [];
      const getAllDoctorPatientsAsync = async (
        patientData,
        newPatientsEvolution,
        newPatientsWithoutQuests
      ) => {
        const resEvolution = await fetch(
          `${API_URL}/v2/patient/quest/all?doctor-id=${appContext.email}&adaf-id=${patientData.id}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              Authorization: appContext.jwt
            }
          }
        );
        if (resEvolution.status === 200) {
          let patientEvolution = await resEvolution.json();
          // patientEvolution = { id: patientData.id, ...patientEvolution }; // When deployment
          // ///// ONLY FOR TESTING WE SHIFT DATES ////////
          // const oneDay = 865e5;
          // const twentyOneDays = oneDay * 21;
          // let tempPatientEvolution = Object.fromEntries(
          //   Object.entries(patientEvolution).map(([key, value], index) => [
          //     `${Number(key) + twentyOneDays * index}`,
          //     value
          //   ])
          // );
          // console.log(tempPatientEvolution);
          // tempPatientEvolution = { id: patientData.id, ...tempPatientEvolution };
          // newPatientsEvolution.push(tempPatientEvolution);
          // //////////////////////////////////////////////
          patientEvolution = { id: patientData.id, ...patientEvolution };
          newPatientsEvolution.push(patientEvolution);
        } else {
          newPatientsWithoutQuests.push(patientData.id);
        }
      };
      const results = [];
      allDoctorPatientsData.forEach((patientData) => {
        try {
          results.push(
            getAllDoctorPatientsAsync(patientData, newPatientsEvolution, newPatientsWithoutQuests)
          );
        } catch (e) {
          // Nothing to do
        }
      });
      await Promise.all(results);
      console.log(newPatientsEvolution);
      setAllDoctorPatientsEvolution(newPatientsEvolution);
      setPatientsWithoutQuests(newPatientsWithoutQuests);
    };
    getAllDoctorPatientsEvolution();
  }, [appContext.email, allDoctorPatientsData]);

  useEffect(() => {
    const getAllGlobalPatientsEvolution = async () => {
      const newPatientsEvolution = [];
      const getAllGlobalPatientsAsync = async (patientData, newPatientsEvolution) => {
        const resEvolution = await fetch(
          `${API_URL}/v2/patient/quest/all?doctor-id=${patientData['doctor-id']}&adaf-id=${patientData.id}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              Authorization: appContext.jwt
            }
          }
        );
        if (resEvolution.status === 200) {
          const patientEvolution = await resEvolution.json();
          // ///// ONLY FOR TESTING WE SHIFT DATES ////////
          // const oneDay = 865e5;
          // const twentyOneDays = oneDay * 21;
          // const tempPatientEvolution = Object.fromEntries(
          //   Object.entries(patientEvolution).map(([key, value], index) => [
          //     `${Number(key) + twentyOneDays * index}`,
          //     value
          //   ])
          // );
          // console.log(tempPatientEvolution);
          // newPatientsEvolution.push(tempPatientEvolution);
          // //////////////////////////////////////////////
          newPatientsEvolution.push(patientEvolution);
        }
      };
      const results = [];
      allGlobalPatientsData.forEach((patientData) => {
        try {
          results.push(getAllGlobalPatientsAsync(patientData, newPatientsEvolution));
        } catch (e) {
          // Nothing to do
        }
      });
      await Promise.all(results);
      console.log(newPatientsEvolution);
      setAllGlobalPatientsEvolution(newPatientsEvolution);
    };
    getAllGlobalPatientsEvolution();
  }, [appContext.email, allGlobalPatientsData]);

  // Retrieve the data of the selected patients
  useEffect(() => {
    // console.log(selectionModel);
    const getSelectedPatientsEvolution = () => {
      const newPatientsEvolution = [];
      allDoctorPatientsData.forEach((patientData, index) => {
        if (
          selectionModel.includes(patientData.id) &&
          !patientsWithoutQuests.includes(patientData.id)
        ) {
          // console.log(patientData.id, allDoctorPatientsEvolution);
          const patientEvolution = {
            ...allDoctorPatientsEvolution.filter(
              (patientEvolution) => patientEvolution.id === patientData.id
            )[0]
          };
          delete patientEvolution.id;
          newPatientsEvolution.push(patientEvolution);
        } else if (
          selectionModel.includes(patientData.id) &&
          patientsWithoutQuests.includes(patientData.id)
        ) {
          setOpenSnackbar(true);
        }
      });
      setSelectedPatientsEvolution(newPatientsEvolution);
      // console.log(selectedPatientsEvolution);
    };
    const getSelectedPatientsData = () => {
      const newPatientsData = allDoctorPatientsData.filter((patientData) =>
        selectionModel.includes(patientData.id)
      );
      setSelectedPatientsData(newPatientsData);
    };
    setOpenSnackbar(false);
    getSelectedPatientsEvolution();
    getSelectedPatientsData();
  }, [selectionModel, allDoctorPatientsData, allDoctorPatientsEvolution]);

  return (
    <RootStyle title="Análisis de pacientes | ADAF">
      <Container maxWidth="xl">
        <ContentStyle fullWidth>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Análisis de los datos de los pacientes
            </Typography>
          </Box>

          <DataDialog
            patients={allDoctorPatientsData}
            selectionModel={selectionModel}
            onSelectionChange={onSelectionChange}
          />

          <Divider sx={{ mt: 5, mb: 5 }} />

          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert severity="warning" sx={{ width: '100%' }}>
              El paciente seleccionado no ha hecho ningún cuestionario
            </Alert>
          </Snackbar>

          {selectionModel.length === 1 &&
          selectedPatientsEvolution[0] &&
          Object.keys(selectedPatientsEvolution[0]).length > 0 ? (
            <>
              <GraphAdaf
                patientData={selectedPatientsData[0]}
                patientEvolution={selectedPatientsEvolution[0]}
              />
              <GraphEvolution
                patientData={selectedPatientsData[0]}
                patientEvolution={selectedPatientsEvolution[0]}
              />
              <GraphToPsychology
                patientData={selectedPatientsData[0]}
                patientEvolution={selectedPatientsEvolution[0]}
              />
            </>
          ) : null}
          {selectionModel.length > 1 &&
          selectedPatientsEvolution[0] &&
          Object.keys(selectedPatientsEvolution[0]).length > 0 ? (
            <>
              <GraphCompAnxiety
                selectedPatientsData={selectedPatientsData}
                selectedPatientsEvolution={selectedPatientsEvolution}
                allPatientsData={allGlobalPatientsData}
                allPatientsEvolution={allGlobalPatientsEvolution}
              />
              <GraphCompDepression
                selectedPatientsData={selectedPatientsData}
                selectedPatientsEvolution={selectedPatientsEvolution}
                allPatientsData={allGlobalPatientsData}
                allPatientsEvolution={allGlobalPatientsEvolution}
              />
              <GraphCompAfrontEvit
                selectedPatientsData={selectedPatientsData}
                selectedPatientsEvolution={selectedPatientsEvolution}
                allPatientsData={allGlobalPatientsData}
                allPatientsEvolution={allGlobalPatientsEvolution}
              />
              <GraphCompAfrontIndef
                selectedPatientsData={selectedPatientsData}
                selectedPatientsEvolution={selectedPatientsEvolution}
                allPatientsData={allGlobalPatientsData}
                allPatientsEvolution={allGlobalPatientsEvolution}
              />
            </>
          ) : null}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
