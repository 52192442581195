import { useState, useRef, useContext } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Typography, Container } from '@mui/material';
// Print component
import { useReactToPrint } from 'react-to-print';
// components
import { QRViewer } from '../components/_dashboard/patients';
import Page from '../components/Page';
import RegisterPatientForm from '../components/authentication/register/RegisterPatientForm';
import AppContext from '../context/AppContext';
import { API_URL } from '../properties';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: theme.spacing(0),
  alignContent: 'center',
  display: 'flex',
  minHeight: '50vh',
  flexDirection: 'column',
  justifyContent: 'top',
  padding: theme.spacing(1, 0)
}));

// ----------------------------------------------------------------------

export default function RegisterPacient() {
  const appContext = useContext(AppContext);
  const [submitted, setSubmitted] = useState(false);
  const [adafId, setAdafId] = useState('');

  const onSubmit = async (data) => {
    setSubmitted(true);
    console.log(JSON.stringify(data));
    try {
      const resPatient = await fetch(`${API_URL}/v2/patient?doctor-id=${appContext.email}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: appContext.jwt
        },
        body: JSON.stringify(data)
      });
      const adafId = await resPatient.json();
      console.log(adafId);
      setAdafId(adafId);
    } catch (e) {
      // console.log(e);
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <RootStyle title="Registrar paciente | ADAF">
      <Container maxWidth="xl">
        <ContentStyle ref={componentRef} fullWidth>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h4" gutterBottom>
              {adafId === '' ? 'Registrar un nuevo paciente' : 'QR del nuevo paciente'}
            </Typography>
          </Box>
          <Card sx={{ mt: 2, p: 5, pt: 7, pb: 7 }} fullWidth>
            {adafId === '' ? (
              <RegisterPatientForm onSubmit={(data) => onSubmit(data)} />
            ) : (
              <QRViewer adafId={adafId} handlePrint={handlePrint} />
            )}
          </Card>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
