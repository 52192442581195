import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DataTable from './DataTable';

export default function DataDialog(props) {
  const { patients, selectionModel, onSelectionChange } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top',
        marginBottom: 2
      }}
    >
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}
        size="large"
        sx={{ alignSelf: 'flex-start', maxWidth: 300 }}
        fullWidth
      >
        Seleccionar pacientes a ver
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
        <DialogTitle>Pacientes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selecciona de la siguiente tabla de qué paciente(s) estaría interesado en ver sus datos.
          </DialogContentText>
          <DataTable
            patients={patients}
            selectionModel={selectionModel}
            onSelectionChange={onSelectionChange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
