// material
import { Box, Card, CardHeader } from '@mui/material';
import { merge } from 'lodash';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
//
import { BaseOptionChart } from '../../charts';
import {
  COLOR_AFRONT_EVIT,
  COLOR_AFRONT_INDEF,
  COLOR_ANXIETY,
  COLOR_DEPRESSION,
  COLOR_LAST_CHECK,
  COLOR_STAGE,
  COLOR_REASON,
  COLOR_TIME_DIAGNOSIS,
  COLOR_NAUDIOS
} from '../../../utils/constants';

// ----------------------------------------------------------------------

export default function GraphAdaf(props) {
  const { patientData, patientEvolution } = props;

  const [anxiety, setAnxiety] = useState([]);
  const [depression, setDepression] = useState([]);
  const [afrontEvit, setAfrontEvit] = useState([]);
  const [afrontIndef, setAfrontIndef] = useState([]);
  const [datesStr, setDatesStr] = useState([]);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    // Get questions from response
    const newAnxiety = Object.keys(patientEvolution)
      .map((key) => patientEvolution[key].questions)
      .map((questions) => {
        let auxAnxiety = 0;
        questions.forEach((question) => {
          switch (question['question-id']) {
            case '1b':
              auxAnxiety += question.answer;
              break;
            case '1c': // Optional
              auxAnxiety += question.answer;
              break;
            default:
              break;
          }
        });
        return auxAnxiety;
      });
    const newDepression = Object.keys(patientEvolution)
      .map((key) => patientEvolution[key].questions)
      .map((questions) => {
        let auxDepression = 0;
        questions.forEach((question) => {
          switch (question['question-id']) {
            case '2b':
              auxDepression += question.answer;
              break;
            case '2c': // Optional
              auxDepression += question.answer;
              break;
            default:
              break;
          }
        });
        return auxDepression;
      });
    const newAfrontIndef = Object.keys(patientEvolution)
      .map((key) => patientEvolution[key].questions)
      .map((questions) => {
        let auxAfrontIndef = 0;
        questions.forEach((question) => {
          switch (question['question-id']) {
            case '3b':
              auxAfrontIndef += question.answer;
              break;
            default:
              break;
          }
        });
        return auxAfrontIndef;
      });
    const newAfrontEvit = Object.keys(patientEvolution)
      .map((key) => patientEvolution[key].questions)
      .map((questions) => {
        let auxAfrontEvit = 0;
        questions.forEach((question) => {
          switch (question['question-id']) {
            case '4b':
              auxAfrontEvit += question.answer;
              break;
            default:
              break;
          }
        });
        return auxAfrontEvit;
      });
    setAnxiety(newAnxiety);
    setDepression(newDepression);
    setAfrontIndef(newAfrontIndef);
    setAfrontEvit(newAfrontEvit);

    // Get dates
    const newDates = Object.keys(patientEvolution).map((key) => new Date(Number(key)));
    // console.log(newDates);
    setDates(newDates);
    const newDatesStr = Object.keys(patientEvolution)
      .map((key) => new Date(Number(key)))
      .map((date) => `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
    setDatesStr(newDatesStr);
  }, [patientEvolution]);

  // const questions = Object.keys(patientEvolution).map((key) => patientEvolution[key])[0].questions;
  // questions.forEach((question) => {
  //   switch (question['question-id']) {
  //     case '1a':
  //       break;
  //     case '2a':
  //       break;
  //     case '3a':
  //       break;
  //     case '4a':
  //       break;
  //     case '1b':
  //       const newAnxiety = anxiety + question.answer;
  //       setAnxiety(newAnxiety);
  //       break;
  //     case '2b':
  //       const newDepression = depression + question.answer;
  //       setDepression(newDepression);
  //       break;
  //     case '3b':
  //       const newAfrontIndef = afrontIndef + question.answer;
  //       setAfrontEvit(newAfrontIndef);
  //       break;
  //     case '4b':
  //       const newAfrontEvit = afrontEvit + question.answer;
  //       setAfrontEvit(newAfrontEvit);
  //       break;
  //     case '1c': // Optional
  //       const newAnxiety = anxiety + question.answer;
  //       setAnxiety(newAnxiety);
  //       break;
  //     case '2c': // Optional
  //       const newDepression = depression + question.answer;
  //       setDepression(newDepression);
  //       break;
  //   }
  // });

  const getChartData = (anxiety, depression, afrontEvit, afrontIndef) => [
    {
      name: 'Ansiedad',
      type: 'column',
      data: anxiety
    },
    {
      name: 'Depresión',
      type: 'column',
      data: depression
    },
    {
      name: 'Afront. Evitativo',
      type: 'column',
      data: afrontEvit
    },
    {
      name: 'Afront. Indefensión',
      type: 'column',
      data: afrontIndef
    }
  ];

  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true
        }
      }
    },
    colors: [COLOR_ANXIETY, COLOR_DEPRESSION, COLOR_AFRONT_EVIT, COLOR_AFRONT_INDEF],
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    stroke: {
      width: 2,
      show: true
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      bar: {
        columnWidth: '55%',
        endingShape: 'rouded',
        horizontal: false
      }
    },
    fill: {
      opacity: 1
    },
    xaxis: {
      title: {
        text: 'Fecha de cuestionario'
      },
      type: 'categories',
      categories: datesStr
    },
    yaxis: {
      title: {
        text: 'Puntuación'
      },
      max: 6,
      tickAmount: 6,
      labels: {
        minWidth: 10
      }
    },
    tooltip: {
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y}`;
          }
          return y;
        }
      }
    },
    annotations: {
      yaxis: [
        {
          y: 3,
          borderColor: COLOR_ANXIETY,
          label: {
            borderColor: COLOR_ANXIETY,
            style: {
              color: '#fff',
              background: COLOR_ANXIETY
            },
            text: 'Depresión'
          }
        },
        {
          y: 2,
          borderColor: COLOR_AFRONT_INDEF,
          label: {
            borderColor: COLOR_AFRONT_INDEF,
            style: {
              color: '#fff',
              background: COLOR_AFRONT_INDEF
            },
            text: 'Resto'
          }
        }
      ]
    }
  });

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader title="Evolución paciente" subheader="Cuestionario ADAF" />
      <Box
        id="adaf"
        sx={{
          p: 3,
          pb: 1
        }}
        dir="ltr"
      >
        <ReactApexChart
          type="bar"
          series={getChartData(anxiety, depression, afrontEvit, afrontIndef)}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
