import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { DataTableOneSelection } from '.';

export default function DataDialogOneSelection(props) {
  const { patients, selectionModel, onSelectionChange } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top',
        marginBottom: 2
      }}
    >
      <Button
        variant="contained"
        onClick={handleClickOpen}
        color="secondary"
        sx={{ alignSelf: 'flex-start', maxWidth: 300 }}
        size="large"
      >
        Seleccionar paciente a ver
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
        sx={{ minHeight: 800, p: 5 }}
      >
        <DialogTitle sx={{ mt: 5 }}>Pacientes</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 5 }}>
            Selecciona de la siguiente tabla de qué paciente estaría interesado en ver sus datos.
          </DialogContentText>
          <DataTableOneSelection
            patients={patients}
            selectionModel={selectionModel}
            onSelectionChange={onSelectionChange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} sx={{ m: 3 }} size="large">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
