import { Box, Divider, Typography, Container, Card } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import { useEffect, useState, useContext } from 'react';
import { UpdatePatientForm } from '../components/authentication/register';
import Page from '../components/Page';
import AppContext from '../context/AppContext';
import { DataDialogOneSelection } from '../components/_dashboard/patients';
import { API_URL } from '../properties';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    justifyContent: 'top'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 0,
  alignSelf: 'flex-start',
  alignContent: 'center',
  display: 'flex',
  minHeight: '50vh',
  flexDirection: 'column',
  justifyContent: 'top',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ManagePatients() {
  const appContext = useContext(AppContext);
  const [allDoctorPatientsData, setAllDoctorPatientsData] = useState([]);
  const [selectedPatientsData, setSelectedPatientsData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [adafId, setAdafId] = useState('');

  const onSelectionChange = (newSelection) => {
    // console.log(newSelection);
    setSelectionModel(newSelection);
  };

  const onSubmit = async (data) => {
    setAdafId('');
    console.log(JSON.stringify(data));
    try {
      const resPatient = await fetch(
        `${API_URL}/v2/patient?doctor-id=${appContext.email}&adaf-id=${selectedPatientsData[0].id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: appContext.jwt
          },
          body: JSON.stringify(data)
        }
      );
      const adafId = await resPatient.json();
      console.log(adafId);
      setAdafId(adafId);
    } catch (e) {
      // console.log(e);
    }
  };

  // Retrieve the patients of the doctor
  useEffect(() => {
    const getAllDoctorPatientsData = async () => {
      const res = await fetch(`${API_URL}/v2/patients/doctor?doctor-id=${appContext.email}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: appContext.jwt
        }
      });
      const patients = await res.json();
      setAllDoctorPatientsData(patients);
      // console.log(patients);
    };

    getAllDoctorPatientsData();
  }, [appContext.email]);

  // Retrieve the data of the selected patients
  useEffect(() => {
    const getSelectedPatientsData = () => {
      const newPatientsData = allDoctorPatientsData.filter((patientData) =>
        selectionModel.includes(patientData.id)
      );
      setSelectedPatientsData(newPatientsData);
    };

    getSelectedPatientsData();
  }, [selectionModel, allDoctorPatientsData]);

  return (
    <RootStyle title="Gestión de pacientes | ADAF">
      <Container maxWidth="xl">
        <ContentStyle fullWidth>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Gestión de los datos de los pacientes
            </Typography>
          </Box>

          <DataDialogOneSelection
            patients={allDoctorPatientsData}
            selectionModel={selectionModel}
            onSelectionChange={onSelectionChange}
          />

          <Divider sx={{ mb: 5, mt: 5 }} />

          {selectedPatientsData.length === 1 && selectedPatientsData[0] ? (
            <Card sx={{ mt: 2, p: 5, pt: 7, pb: 7 }} fullWidth>
              <UpdatePatientForm
                onSubmit={(data) => onSubmit(data)}
                patientData={selectedPatientsData[0]}
                updated={adafId !== ''}
              />
            </Card>
          ) : null}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
