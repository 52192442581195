// material
import { Box, Card, CardHeader } from '@mui/material';
import { max, merge } from 'lodash';
// React
import { useEffect, useState } from 'react';
// Apexcharts
import ReactApexChart from 'react-apexcharts';
import { BaseOptionChart } from '../../charts';
import {
  COLOR_AFRONT_EVIT,
  COLOR_AFRONT_INDEF,
  COLOR_ANXIETY,
  COLOR_DEPRESSION,
  COLOR_LAST_CHECK,
  COLOR_STAGE,
  COLOR_REASON,
  COLOR_TIME_DIAGNOSIS,
  COLOR_NAUDIOS
} from '../../../utils/constants';

// ----------------------------------------------------------------------

export default function GraphToPsychology(props) {
  const { patientData, patientEvolution } = props;

  const [naudios, setNAudios] = useState([]);
  const [datesStr, setDatesStr] = useState([]);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    // Get dates
    const newDates = Object.keys(patientEvolution).map((key) => new Date(Number(key)));
    setDates(newDates);
    const newDatesStr = Object.keys(patientEvolution)
      .map((key) => new Date(Number(key)))
      .map((date) => `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
    setDatesStr(newDatesStr);

    // Get questions from response
    const newNAudios = Object.values(patientEvolution).map((quest) => quest.audios.length);
    setNAudios(newNAudios);
  }, [patientEvolution]);

  const getChartData = (naudios) => [
    {
      name: 'Nº audios escuchados desde el último cuestionario',
      type: 'line',
      data: naudios
    }
  ];

  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true
        }
      }
    },
    colors: [COLOR_NAUDIOS],
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    xaxis: {
      title: {
        text: 'Fecha de cuestionario'
      },
      type: 'categories',
      categories: datesStr
    },
    yaxis: {
      title: {
        text: 'Nº Audios'
      },
      labels: {
        minWidth: 10
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y}`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader title="Evolución paciente" subheader="Audios" />
      <Box id="psychology" sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={getChartData(naudios)}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
