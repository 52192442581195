import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { RegisterForm } from '../components/authentication/register';
import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    maxHeight: '90%'
    // overflow: 'hidden'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: theme.palette.primary.lighter
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  return (
    <RootStyle title="Registro | ADAF">
      <MHidden width="mdDown">
        <SectionStyle sx={{ backgroundColor: '#212B36' }}>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Registrar usuarios
          </Typography>
          <img
            alt="register"
            src="/static/illustrations/saturnino.svg"
            height={400}
            sx={{ objectFit: 'contain' }}
          />
        </SectionStyle>
      </MHidden>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Crear usuario
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Crea un doctor, investigador o administrador
            </Typography>
          </Box>

          <RegisterForm />

          <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
            Al registrarte, aceptas los &nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }}>
              Términos y Condiciones
            </Link>{' '}
            y la &nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }}>
              Política de Privacidad
            </Link>
            &nbsp;de ADAF.
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
