// material
import { Box, Card, CardHeader } from '@mui/material';
import { merge } from 'lodash';
// React
import { useEffect, useState } from 'react';
// Apexcharts
import ReactApexChart from 'react-apexcharts';
import {
  COLOR_AFRONT_EVIT,
  COLOR_AFRONT_EVIT_TOT,
  COLOR_STAGE,
  COLOR_STAGE_TOT
} from '../../../utils/constants';
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

export default function GraphCompAfrontEvit(props) {
  const { allPatientsData, allPatientsEvolution, selectedPatientsData, selectedPatientsEvolution } =
    props;

  const [afrontEvit, setAfrontEvit] = useState([]);
  const [afrontEvitTotal, setAfrontEvitTotal] = useState([]);
  const [stage, setStage] = useState([]);
  const [stageTotal, setStageTotal] = useState([]);
  const [datesStr, setDatesStr] = useState([]);
  const [dates, setDates] = useState([]);

  const getValueClosestQuestion = (questionId, patientEvolution, date) => {
    let distance = Infinity;
    let value = -1;
    let questionsClosestDate = [];
    const patientDates = Object.keys(patientEvolution).map((key) => new Date(Number(key)));
    patientDates.forEach((patientDate) => {
      const newDistance = Math.abs(patientDate - date);
      if (newDistance < distance) {
        distance = newDistance;
        questionsClosestDate = patientEvolution[patientDate.getTime()].questions;
      }
    });
    questionsClosestDate.forEach((question) => {
      if (question['question-id'] === questionId) value = question.answer;
    });
    console.assert(value >= 0);
    return value;
  };

  const getClosestStage = (patientData, date) => {
    let distance = Infinity;
    let stage = 0;
    const stagesDates = patientData.stage.map((el) => new Date(Object.keys(el)[0]));
    stagesDates.forEach((stageDate) => {
      const newDistance = Math.abs(stageDate - date);
      if (newDistance < distance) {
        distance = newDistance;
        // console.log(
        //   patientData.stage.filter((el) => Object.keys(el)[0] === stageDate.toISOString())
        // );
        [stage] = Object.values(
          patientData.stage.filter((el) => Object.keys(el)[0] === stageDate.toISOString())[0]
        );
      }
    });
    console.assert(stage > 0);
    return stage;
  };

  useEffect(() => {
    // Get 10 dates equidistant between first and last dates (including both)
    const newDates = [];
    let firstDate = new Date(Date.now()); // First date possible
    let lastDate = new Date(0); // Today
    selectedPatientsEvolution.forEach((patientEvolution) => {
      Object.keys(patientEvolution)
        .map((key) => new Date(Number(key)))
        .forEach((patientDate) => {
          if (patientDate < firstDate) firstDate = patientDate;
          if (patientDate > lastDate) lastDate = patientDate;
        });
    });
    const deltaTotal = lastDate - firstDate; // Should be ms
    const deltaDates = deltaTotal / 10;
    let newDate = firstDate;
    newDates.push(newDate);
    while (newDate < lastDate && newDates.length < 9) {
      newDate = new Date(newDate.getTime() + deltaDates);
      newDates.push(newDate);
    }
    newDates.push(lastDate);
    const newDatesStr = newDates.map(
      (date) => `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    );
    setDates(dates);
    setDatesStr(newDatesStr);

    // Get questions from response
    const newAfrontEvit = newDates.map(
      (date) =>
        selectedPatientsEvolution
          .map((patientEvolution) => getValueClosestQuestion('4b', patientEvolution, date))
          .reduce((prev, curr) => prev + curr) / selectedPatientsEvolution.length
    );
    const newAfrontEvitTotal = newDates.map(
      (date) =>
        allPatientsEvolution
          .map((patientEvolution) => getValueClosestQuestion('4b', patientEvolution, date))
          .reduce((prev, curr) => prev + curr) / allPatientsEvolution.length
    );
    const newStage = newDates.map(
      (date) =>
        selectedPatientsData
          .map((patientData) => getClosestStage(patientData, date))
          .reduce((prev, curr) => prev + curr) / selectedPatientsData.length
    );
    const newStageTotal = newDates.map(
      (date) =>
        allPatientsData
          .map((patientData) => getClosestStage(patientData, date))
          .reduce((prev, curr) => prev + curr) / allPatientsData.length
    );

    setAfrontEvit(newAfrontEvit);
    setAfrontEvitTotal(newAfrontEvitTotal);
    setStageTotal(newStageTotal);
    setStage(newStage);
  }, [selectedPatientsData, selectedPatientsEvolution, allPatientsData, allPatientsEvolution]);

  const getChartData = (afrontEvit, afrontEvitTotal, stage, stageTotal) => [
    {
      name: 'Afront. evitativo medio de pacientes seleccionados',
      type: 'column',
      data: afrontEvit
    },
    {
      name: 'Afront. evitativo medio del total de pacientes',
      type: 'column',
      data: afrontEvitTotal
    },
    {
      name: 'Estadio medio de pacientes seleccionados',
      data: stage
    },
    {
      name: 'Estadio medio del total de pacientes',
      data: stageTotal
    }
  ];

  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true
        }
      }
    },
    colors: [COLOR_AFRONT_EVIT, COLOR_AFRONT_EVIT_TOT, COLOR_STAGE, COLOR_STAGE_TOT],
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    stroke: {
      width: 2,
      show: true
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      bar: {
        columnWidth: '55%',
        endingShape: 'rouded',
        horizontal: false
      }
    },
    fill: {
      opacity: 1
    },
    xaxis: {
      title: {
        text: 'Fecha de cuestionario'
      },
      type: 'category',
      categories: datesStr
    },
    yaxis: {
      title: {
        text: 'Nivel de afrontamiento evitativo'
      },
      max: 4,
      tickAmount: 4
    },
    tooltip: {
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y}`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card sx={{ mt: 7 }}>
      <CardHeader title="Evolución pacientes" subheader="Afrontamiento Evitativo" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={getChartData(afrontEvit, afrontEvitTotal, stage, stageTotal)}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
