import { useState, useEffect } from 'react';

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

import AppContext from './context/AppContext';
import { getCurrentUser } from './domain/hooks';

import { API_URL } from './properties';
// ----------------------------------------------------------------------

// App

export default function App() {
  const [jwt, setJWT] = useState(null);
  const [expiration, setExpiration] = useState(0);
  const [email, setEmail] = useState(null);
  const [group, setGroup] = useState('');
  const [forceRegister, setForceRegister] = useState(true);

  useEffect(() => {
    const loadAuth = async () => {
      const user = await getCurrentUser();
      if (user === null) {
        setJWT(null);
        setExpiration(0);
        setEmail(null);
      } else {
        setJWT(user.signInUserSession.idToken.jwtToken);
        setExpiration(user.signInUserSession.idToken.payload.exp * 1000);
        setEmail(user.attributes.email);
        setGroup(user.signInUserSession.idToken.payload['custom:roles']);
      }
    };

    loadAuth();
  }, []);

  useEffect(() => {
    getDoctorData();
  }, [email]);

  useEffect(() => {
    if (group === 'Investigador') {
      setForceRegister(false);
    }
  }, [group]);

  const getDoctorData = async () => {
    try {
      const resDoctor = await fetch(`${API_URL}/v2/doctor`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: jwt
        }
      });
      if (resDoctor.status === 200) {
        const doctorData = await resDoctor.json();
        if (doctorData === null) {
          setForceRegister(true);
          console.log('A');
        } else {
          setForceRegister(false);
          console.log('B');
        }
        console.log('Doctor data', doctorData);
        console.log('C');
      }
    } catch (e) {
      setForceRegister(true);
      console.log('D');
      // console.log(e);
    }
  };

  return (
    <ThemeConfig>
      <AppContext.Provider
        value={{
          jwt,
          setJWT,
          expiration,
          setExpiration,
          email,
          setEmail,
          group,
          setGroup,
          forceRegister,
          setForceRegister
        }}
      >
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router />
      </AppContext.Provider>
    </ThemeConfig>
  );
}
