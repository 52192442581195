import { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AppContext from './context/AppContext';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import AnalysisPatients from './pages/AnalysisPatients';
import AnalysisResearchers from './pages/AnalysisResearchers';
import Login from './pages/Login';
import ManagePatients from './pages/ManagePatients';
import NotFound from './pages/Page404';
import Recovery from './pages/Recovery';
import Register from './pages/Register';
import RegisterDoctor from './pages/RegisterDoctor';
import RegisterPatient from './pages/RegisterPatient';

// ----------------------------------------------------------------------

export default function Router() {
  const appContext = useContext(AppContext);

  return useRoutes([
    {
      path: '/dashboard',
      element:
        appContext.expiration > +new Date() ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/login" replace />
        ),
      children: [
        {
          element:
            appContext.group === 'Investigador' ? (
              <Navigate to="/dashboard/analysis/researchers" replace />
            ) : (
              <Navigate to="/dashboard/profile" replace />
            )
        },
        {
          path: 'profile',
          element:
            appContext.group === 'Investigador' ? (
              <Navigate to="/dashboard/analysis/researchers" replace />
            ) : (
              <RegisterDoctor />
            )
        },
        {
          path: 'register/patients',
          element:
            appContext.forceRegister && appContext.group !== 'Investigador' ? (
              <Navigate to="/dashboard/profile" replace />
            ) : (
              <RegisterPatient />
            )
        },
        {
          path: 'manage/patients',
          element:
            appContext.forceRegister && appContext.group !== 'Investigador' ? (
              <Navigate to="/dashboard/profile" replace />
            ) : (
              <ManagePatients />
            )
        },
        {
          path: 'analysis/patients',
          element: appContext.forceRegister ? (
            <Navigate to="/dashboard/profile" replace />
          ) : (
            <AnalysisPatients />
          )
        },
        {
          path: 'analysis/researchers',
          element: ['investigador', 'administrador'].includes(
            (appContext?.group ?? '').toLowerCase()
          ) ? (
            <AnalysisResearchers />
          ) : (
            <Navigate to="/dashboard/profile" replace />
          )
        },
        {
          path: 'register/users',
          element:
            (appContext?.group ?? '').toLowerCase().includes('admin') &&
            !appContext.forceRegister ? (
              <Register />
            ) : (
              <Navigate to="/dashboard/profile" replace />
            )
        }
      ]
    },
    {
      path: '/',
      element:
        appContext.expiration > +new Date() && appContext.group !== 'Investigador' ? (
          <Navigate to="/dashboard/profile" replace />
        ) : (
          <LogoOnlyLayout />
        ),
      children: [
        { path: 'login', element: <Login /> },
        { path: 'recovery', element: <Recovery /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard/profile" replace /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
